import React from "react"
// import { graphql } from "gatsby"
import "twin.macro"
import Container from "../components/container"
// import ApplicationForm from "../components/form/application-form"
import BackButton from "../components/back-button"
import "../css/gutenberg.css"

const FormTemplate = ({ data }) => {
  return (
    <>
      <Container>
        <div tw="mt-6 mb-11">
          <BackButton />
        </div>
        <div tw="mb-6 lg:mb-10">
          <h1 tw="mb-10 max-w-5xl text-3xl font-bold lg:mb-18 lg:text-5xl">
            zusammen:wachsen <br /> 1. Gleichstellungstag
          </h1>
          <div tw="grid grid-cols-12 gap-5" className="wp-content">
            <div tw="col-span-12 -mx-4 bg-sand-500 px-4 py-8 text-sm hyphens-auto sm:-mx-10 sm:px-10 lg:col-span-2 lg:mx-0 lg:bg-transparent lg:px-0 lg:pb-0 lg:pt-1.5">
              <strong>Veranstaltungsort</strong>
              <br />
              KINDL
              <br />
              Am Sudhaus 3<br />
              12053 Berlin
            </div>
            <div tw="col-span-12 lg:col-span-10">
              <h2 tw="text-3xl font-bold">Anmeldefrist abgelaufen</h2>
              <p tw="text-xl font-bold">
                Herzlichen Dank für Ihr Interesse am Gleichstellungstag.
                <br />
                Leider ist die Anmeldefrist für den 1. Gleichstellungstag
                bereits abgelaufen. <br />
                Bei Rückfragen wenden Sie sich gerne an<br />
                <a href="mailto:gleichstellungstag@bundesstiftung-gleichstellung.de">
                  gleichstellungstag@bundesstiftung-gleichstellung.de
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default FormTemplate

// export { Head } from "../components/head"

// export const pageQuery = graphql`
//   query HomeById {
//     seo: wpPage(id: { eq: "cG9zdDoxNzM0" }) {
//       ...SeoFragment
//     }
//   }
// `
